export async function loadWidget(pName: string) {
    const chartEl = document.createElement('div');
    document.body.append(chartEl);
    // chartEl.style.width = "100vw";
    // chartEl.style.height = "100vh";

    function getImportMapNode() {
        return document.querySelector('script[type="importmap"]') ?? document.querySelector('script[type="importmap-shim"]')
    }


    const importMaps = JSON.parse(getImportMapNode()!.innerHTML);
    const highchartsPromise = new Promise<void>(res => {
        const script = document.createElement('script');
        script.src = importMaps.imports.highcharts;
        script.onload = () => res();
        document.body.append(script);
    });

    highchartsPromise.then(() => {});
    const jsonLoadPath = Object.entries(importMaps.imports).find(entry => entry[0].endsWith(pName))[1] as string;
    const promises: Promise<void>[] = [];
    let app: Awaited<ReturnType<typeof getAppConfig>> | null= null;
    promises.push(getAppConfig().then(a => {app = a;}));

    let json: any = null;
    promises.push(fetch(jsonLoadPath).then(r => r.json()).then(r => { json = r; }));
    await Promise.all(promises);
    const dsId = json.options.dataObject;
    const dsConfig = app.dataObjectConfigs.get(dsId);
    let data = [];
    appendQueryParameters(dsConfig, app);
    const options: any = {
        dataSourceId: dsConfig.id,
        operation: 'retrieve',
        viewName: dsConfig.viewName,
        fields: dsConfig.fields,
        definitionProc: dsConfig.definitionProc,
        maxRecords: dsConfig.maxRecords,
        distinctRows: dsConfig.distinctRows,
        whereClause: dsConfig.whereClause,
        filterString: dsConfig.filterString
    }
    if (dsConfig.definitionProc) {
        options.definitionProc = dsConfig.definitionProc;
        options.contextId = window.__navBarData.orgUnit.id
        const sqlStatementParameters = Object.fromEntries(Object.entries(dsConfig.sqlStatementParameters).map((entry) => {
            return [entry[0], entry[1] == '' ? null : entry[1]];
        }));
        options.sqlStatementParameters = sqlStatementParameters;
        options.definitionProcParameters = dsConfig.definitionProcParameters;
    }
    const chartInstancePromise = highchartsPromise.then(() => {
        const instnace = Highcharts.chart(chartEl, json.options);
        instnace.showLoading();
        return instnace;
    });

    fetch(`/api/data/${dsConfig.viewName}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'X-NT-API': 'true'
        },
        body: JSON.stringify(options)
    }).then(res => res.json()).then(res => res.success)
    .then(data => {
        chartInstancePromise.then(chart => {
            chart.hideLoading();
            chart.update(applyDataToOptions(json.options, data), true, true);
        })
    });    
}
async function getAppConfig() {
    const app = {
        id: (<HTMLMetaElement>document.querySelector('meta[name="o365-app-id"]'))?.content,
        parentId: (<HTMLMetaElement>document.querySelector('meta[name="o365-app-parentid"]'))?.content,
        parentTitle: (<HTMLMetaElement>document.querySelector('meta[name="o365-app-parenttitle"]'))?.content,
        title: (<HTMLMetaElement>document.querySelector('meta[name="o365-app-title"]'))?.content,
        fingerprint: (<HTMLMetaElement>document.querySelector('meta[name="o365-app-fingerprint"]'))?.content,
        db_objectfingerprint: (<HTMLMetaElement>document.querySelector('meta[name="o365-dbobjectdefinition-fingerprint"]'))?.content,
        proxyRequest: (<HTMLMetaElement>document.querySelector("[name=o365-proxy-request]"))?.content,
        proxyRequest: (<HTMLMetaElement>document.querySelector("[name=o365-proxy-request]"))?.content,
        filters: {},
        dataObjectConfigs: new Map<string, IDataObjectConfig>(),
        filterTemplates: {},
        isDebug: false,
        config: null,
        queryParameters: {}
    }

    const fingerPrint = app.db_objectfingerprint > app.fingerprint ? app.db_objectfingerprint : app.fingerprint;
    const appInfo = await fetch(`/nt/api/apps/${app.id}.${fingerPrint}.json`).then(res => res.json());
    app.viewDefinitions = appInfo.viewDefinition;

    app.isDebug = appInfo.isDebug ?? false;

    appInfo.dataObjects?.forEach((dataObject: any) => {
        app.dataObjectConfigs.set(dataObject.id, dataObject);
    });

    if (app.dataObjectConfigs.size > 0 && (!app.viewDefinitions || Object.keys(app.viewDefinitions).length === 0)) {
        // App has DataObjects but no view definitions werer eturned
        const warningMessage = `${app.id}.json has DataObject definitions but no view definitions. Most likely one of the DataObjects has invalid or unset view`;
        if (app.isDebug) {
            import('o365-vue-services').then((alertModule) => {
                alertModule.alert(warningMessage, 'warning', { autohide: true, delay: 10000, });
            });
        } else {
            console.warn(warningMessage);
        }
    }
    try {
        if (appInfo.config) {
            const appConfig: IAppConfig = JSON.parse(appInfo.config);

            app.config = appConfig;
        }
    } catch (error) {
        console.warn(error);
    }
    initializeConfiguredQueryParameters(app);
    return app;
}

function initializeConfiguredQueryParameters(app: any) {
    if (app?.config?.queryParameters == null) { return; }
    try {
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => {
                if (prop == 'toString') {
                    return () => searchParams.toString();
                }
                return searchParams.get(prop as any)
            },
            set: (searchParams, prop, value) => {
                if (typeof prop != 'string') { return false; }
                searchParams.set(prop, value);
                return true;
            }
        });
        let defaultValuesFilled = false;
        Object.keys(app.config.queryParameters).forEach(key => {
            if (params[key] == null && app?.config?.queryParameters?.[key]?.default != null ) {
                defaultValuesFilled = true;
                params[key] = app?.config?.queryParameters?.[key]?.default;
            }

            Object.defineProperty(app.queryParameters, key, {
                get() {
                    const value = params[key] ?? app?.config?.queryParameters?.[key]?.default;

                    return app?.config?.queryParameters?.[key]?.type == 'number' && value != null 
                        ? parseInt(value)
                        : value;
                },
            })
        });
        if (defaultValuesFilled) {
            let url = new URL(window.location.href);
            url.search = params.toString();
            window.history.replaceState({}, '', url);
        }
    } catch (ex) {
        console.error(ex);
    }
}

export interface IDataObjectConfig {
    id: string;
    appId?: string;
    viewName: string;
    distinctRows: boolean;
    uniqueTable: string;
    allowUpdate: boolean;
    allowInsert: boolean;
    allowDelete: boolean;
    appendData: boolean;
    selectFirstRowOnLoad: boolean;
    fields: Array<IDataObjectFieldConfig>;
    masterDataObject_ID: string;
    clientSideHandler: string;
    maxRecords: number;
    dynamicLoading: boolean;
    whereClause: string;
    filterString: string;
    // [key: string]: unknown;
}


export interface IDataObjectFieldConfig {
    name: string;
    sortOrder: number;
    sortDirection: string;
    groupByOrder: number;
    groupByAggregate: string;
    [key: string]: unknown;
}

const serieTemplate = {
    name: '',
    data: [],
    yAxis: 0
}

function applyDataToOptions(pOptions: any, data: any[]) {
    const options = JSON.parse(JSON.stringify(pOptions));
    const categoryColumn = options.categoryColumn.name;
    delete options.categoryColumn;
    // const seriesColumns = options.seriesColumns;
    delete options.seriesColumns;
    if (options.series == null || categoryColumn == null) { return options; }

    if (options.xAxis == null) { options.xAxis = {}};

    if (options.chart.type == 'pie') {
        for (let i = 0; i < data.length; i++) {
            options.series[0].data.push({
                name: data[i][categoryColumn],
                y: data[i][options.series[0].name]
            })
        }
        options.series[0].name = categoryColumn;
    } else if (options.plotOptions.series.stacking == 'normal' || options.plotOptions.series.stacking == 'percentage') {
        options.xAxis.categories = data.map(item => item[categoryColumn]);

        const seriesColumnName = options.series[0].name;
        const valueField = pOptions.series[0].valueField;
        delete options.series[0].valueField;
        options.series[0].data = [];
        for (let i = 0; i < data.length; i++) {
            const foundSerie = checkIfSerieExists(options.series, data[i], seriesColumnName);
            if (foundSerie == -1) {
                if (options.series[i] == null) {
                    options.series.push(unRefObject(serieTemplate));
                }
                const index = options.series.length - 1;
                options.series[index].name = data[i][seriesColumnName];
                options.series[index].yAxis = 0;
                options.series[index].data = [];
                options.series[index].data.push(data[i][valueField]);
            } else {
                options.series[foundSerie].data.push(data[i][valueField]);
            }
        }
    } else {
        options.xAxis.categories = data.map(item => item[categoryColumn]);

        options.series.forEach(seriesColumn => {
            seriesColumn.data = data.map(item => item[seriesColumn.name]); 
            if (seriesColumn.animation == null) {
                seriesColumn.animation = {
                    duration: 500,
                    easing: 'easeOutBounce'
                };
            }
        });
    }

    return options;
}

const checkIfSerieExists = (series: any, data: any, seriesColumnName: string) => {
    return series.findIndex((x: any) => x.name == data[seriesColumnName]);
}

const unRefObject = (object: any) => {
    return JSON.parse(JSON.stringify(object));
}

function appendQueryParameters(pOptions:any, app) {
    try {
        const appId = pOptions.appId ?? 'site';
        if (app?.config?.queryParameters) {
            const appendParameter = (pKey: string, pType: 'sql'|'def', pValue: any) => {
                if (pType == 'sql') {
                    if (pOptions.sqlStatementParameters == null) { pOptions.sqlStatementParameters = {}; }
                    pOptions.sqlStatementParameters[pKey] = pValue ?? null;
                } else if (pType == 'def') {
                    if (pOptions.definitionProcParameters == null) { pOptions.definitionProcParameters = {}; }
                    pOptions.definitionProcParameters[pKey] = pValue ?? null;
                }
            }

            const appendClause = (clause: string) => {
                pOptions.whereClause = pOptions.whereClause ? `(${pOptions.whereClause}) AND (${clause})` : clause;
            };
            const getOperator = (binding: string) => {
                switch (binding) {
                    case 'equals':
                        return '=';
                    default:
                        return 'LIKE';
                }
            }

            const getValue = (value: string, binding: string) => {
                switch (binding) {
                    case 'contains':
                        return ` '%${value}%'`;
                    case 'beginswith':
                        return ` '${value}%'`;
                    case 'endswith':
                        return ` '%${value}'`;
                    case 'equals':
                    default:
                        return ` '${value}'`;
                }
            }
            Object.entries(app.config.queryParameters).forEach(([key, _value]) => {
                const value = _value as any;
                if (value?.connection?.dataObject != pOptions.id) { return; }
                switch (value?.connection?.type) {
                    case 'whereClause':
                        if (value.connection?.field == null || value.connection?.binding == null) { return; }
                        appendClause(`[${value.connection.field}] ${getOperator(value.connection.binding)} ${getValue(app.queryParameters[key], value.connection.binding)}`);
                        break;
                    case 'sqlStatementParameter':
                        if (value?.connection?.parameter == null) { return; }
                        appendParameter(key, 'sql', app.queryParameters[key]);
                        break;
                    case 'definitionProcParameter':
                        if (value?.connection?.parameter == null) { return; }
                        appendParameter(key, 'def', app.queryParameters[key]);
                        break;
                }
            });
        }
    } catch (ex) {
        window['console'].error(ex);
    }
}